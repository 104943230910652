<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#444444"
      text-color="rgba(255,255,255,.7)"
      active-text-color="#fff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i style="font-size: 25px; width: 26px" :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template slot="title">
                  <i style="font-size: 20px; width: 20px" :class="subItem.icon"></i
                  >{{ subItem.title }}
                </template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index"
                ><i style="font-size: 20px; width: 20px" :class="subItem.icon"></i
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item v-if="$check(item.code)" :index="item.index" :key="item.index">
            <i style="font-size: 25px; width: 26px" :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../common/bus";
// import {getSysmenu} from '../../api/api'
export default {
  data() {
    return {
      role: 0,
      collapse: false,
      menuItems: [],
      items: [
        {
          icon: "el-icon-s-data",
          index: "/dashboard",
          title: "Analytics",
          code: 10,
        },

        {
          icon: "el-icon-s-shop",
          index: "/stores/list",
          title: "Stores",
          code: 10,
        },
        {
          icon: "el-icon-shopping-bag-1",
          index: "/products/list/select",
          title: "Products",
          code: 20
        },
             {
          icon: "el-icon-shopping-bag-1",
          index: "/products/list/series",
          title: "Series Products",
          code: 20
        },
        {
          icon: "el-icon-video-camera-solid",
          index: "/theme/list",
          title: "Drops",
          code: 20,
        },
        {
          icon: "el-icon-s-operation",
          index: "/size",
          title: "尺寸对照表",
          code: 50,
        },
        {
          icon: "el-icon-s-order",
          index: "card",
          title: "订单管理",
          code: 30,
          subs: [
            {
              icon: "el-icon-document-copy",
              index: "/order/list",
              title: "订单列表",
              code: 10,
            },
            {
              icon: "el-icon-medal-1",
              index: "/order/services",
              title: "售后列表",
              code: 10,
            },
            {
              icon: "el-icon-s-promotion",
              index: "/stats/order",
              title: "订单统计",
              code: 10,
            },
            // {
            //   icon: "el-icon-edit-outline",
            //   index: "/order/services",
            //   title: "评论管理",
            //   code: 10,
            // },
          ],
        },
        {
          icon: "el-icon-s-flag",
          index: "log",
          title: "物流管理",
          code: 40,
          subs: [
            {
              icon: "el-icon-s-fold",
              index: "/express/setting",
              title: "规则设置",
              code: 10,
            },
            {
              icon: "el-icon-s-promotion",
              index: "/express/list",
              title: "物流跟踪",
              code: 10,
            },
          ],
        },
        // {
        //   icon: "el-icon-s-custom",
        //   index: "/user",
        //   title: "用户管理",
        //   code: 50,
        // },
        {
          icon: "el-icon-s-flag",
          index: "stats",
          title: "用户管理",
          code: 50,
          subs: [
            {
              icon: "el-icon-s-promotion",
              index: "/stats/user",
              title: "用户管理",
              code: 10,
            },
            {
              icon: "el-icon-s-promotion",
              index: "/stats/address",
              title: "用户收货地址",
              code: 10,
            },
            {
              icon: "el-icon-s-promotion",
              index: "/stats/echarts",
              title: "图表统计",
              code: 10,
            },
          ],
        },
        {
          icon: "el-icon-s-flag",
          index: "data",
          title: "数据统计",
          code: 50,
          subs: [
            {
              icon: "el-icon-s-promotion",
              index: "/data/product",
              title: "商品浏览数据",
              code: 10,
            },
            {
              icon: "el-icon-s-promotion",
              index: "/data/button",
              title: "按钮数据",
              code: 10,
            },
            // {
            //   icon: "el-icon-s-promotion",
            //   index: "/data/search",
            //   title: "搜索数据",
            //   code: 10,
            // },
          ],
        },
        // {
        //   icon: "el-icon-s-finance",
        //   index: "product",
        //   title: "财务数据",
        //   code: 50,
        // },
        // {
        //   icon: "el-icon-s-platform",
        //   index: "product",
        //   title: "营销中心",
        //   code: 50,
        // },

        {
          icon: "el-icon-message-solid",
          index: "notice",
          title: "消息提醒",
          code: 50,
        },

        {
          icon: "el-icon-s-tools",
          index: "/setting",
          title: "小程序设置",
          code: 50,
        },

        // {
        //   icon: "el-icon-share",
        //   index: "product",
        //   title: "小程序API",
        //   code: 50,
        // },
      ],
    };
  },
  methods: {},
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },

    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
    });
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.sidebar > ul {
  height: 100%;
}
</style>
